.game {
  width: 920px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.header {
  margin: 32px 0;
}

.flexbox {
  display: flex;
  align-items: center;
  justify-content: center;
}
