$transition: .3s;

.card {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 80px;
  margin: 12px 12px;
  box-shadow: 0px 0px 4px 8px rgb(246, 246, 246);
  border-radius: 8px;
  cursor: pointer;
  transition: $transition;
}

.open {
  box-shadow: 0px 0px 4px 8px rgb(188, 245, 249);
}

.check {
  box-shadow: 0px 0px 4px 8px rgb(224, 223, 223);
}

.label {
  font-size: 32px;
  transition: $transition;
}
